<template>
    <div>
        <el-table :data="tableData">
            <el-table-column label="test" />
        </el-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tableData: [],
        };
    },
};
</script>
